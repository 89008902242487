
import { HrEmployees } from './index';
import { HrPsc } from './index';
import { HrWorkSections } from './index';
import { NgTenants } from './index';

export class HrDepartments {
   public departmentId: number = 0;
   public department: string = null;
   public departmentCode: string = null;
   public managerId: number = null;
   public active: boolean = null;
   public tenantId: number = 0;
   public hrEmployees: HrEmployees[] = [];
   public hrPsc: HrPsc[] = [];
   public hrWorkSections: HrWorkSections[] = [];
   public tenant: NgTenants = null;
}