import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'aims-common-common-components',
  template: ` <p>common-components works!</p> `,
  styles: [],
})
export class CommonComponentsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
