import { Directive, Input, HostListener } from '@angular/core'
import { AimsCommonTableEditableComponent } from './aims-common-table-editable.component'

@Directive({
  selector: '[editableOnEnter]',
})
export class EditableOnEnterDirective {
  constructor(private editable: AimsCommonTableEditableComponent) {}

  // @HostListener('keyup.enter')
  // onEnter() {
  //   this.editable.toViewMode();
  // }
}
