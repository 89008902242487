export const environment = {
    debug: 'true',
    name: 'dev01',
    production: 'false',
    apiUrl: 'https://dev01-aims-api.azurewebsites.us/api',
    hubsUrl: 'https://dev01-aims-api.azurewebsites.us',
    reportsHost: 'https://dev01-aims-reports.azurewebsites.us',
    reportsUrl: 'https://dev01-aims-reports.azurewebsites.us/api/Reports',
    assetsPath: 'https://dev01.aimshq.com/assets'
};
