
import { QcPublications } from './index';
import { NgTenants } from './index';

export class QcPubIndexTypes {
   public pubIndexTypeId: number = 0;
   public pubIndexType: string = null;
   public pubIndexTypeTitle: string = null;
   public active: boolean = null;
   public tenantId: number = 0;
   public qcPublications: QcPublications[] = [];
   public tenant: NgTenants = null;
}