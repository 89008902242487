
import { FacEquipment } from './index';
import { FacEquipmentMeters } from './index';
import { WoScheduledMaintenanceTemplates } from './index';
import { NgTenants } from './index';

export class WoScheduledMaintenanceTemplateEquipmentXs {
   public scheduledMaintenanceTemplateEquipmentXsId: number = 0;
   public equipmentId: number = 0;
   public equipmentMeterId: number = null;
   public scheduledMaintenanceTemplateId: number = 0;
   public lastGenDate: Date = null;
   public lastGenHours: number = null;
   public nextGenDate: Date = null;
   public nextGenHours: number = null;
   public nextDueDate: Date = null;
   public nextDueHours: number = null;
   public enabled: boolean = false;
   public generateXdaysBeforeDue: number = 0;
   public generateXhoursBeforeDue: number = 0;
   public genNow: boolean = null;
   public tenantId: number = 0;
   public equipment: FacEquipment = null;
   public equipmentMeter: FacEquipmentMeters = null;
   public scheduledMaintenanceTemplate: WoScheduledMaintenanceTemplates = null;
   public tenant: NgTenants = null;
}