
import { HrDepartments } from './index';
import { FacEquipment } from './index';
import { HrEmployees } from './index';
import { HrJobTitles } from './index';
import { HrOcc } from './index';
import { HrPsc } from './index';
import { OpsScheduled } from './index';
import { OpsScheduledAudit } from './index';
import { QcObservations } from './index';
import { NgTenants } from './index';
import { TrQualTasks } from './index';
import { WorkSectionBudgets } from './index';

export class HrWorkSections {
   public workSectionId: number = 0;
   public departmentId: number = 0;
   public workSection: string = null;
   public leadId: number = null;
   public active: boolean = null;
   public tenantId: number = 0;
   public department: HrDepartments = null;
   public facEquipment: FacEquipment[] = [];
   public hrEmployeesDefaultWorkSection: HrEmployees[] = [];
   public hrEmployeesWorkSection: HrEmployees[] = [];
   public hrJobTitles: HrJobTitles[] = [];
   public hrOcc: HrOcc[] = [];
   public hrPsc: HrPsc[] = [];
   public opsScheduled: OpsScheduled[] = [];
   public opsScheduledAudit: OpsScheduledAudit[] = [];
   public qcObservations: QcObservations[] = [];
   public tenant: NgTenants = null;
   public trQualTasks: TrQualTasks[] = [];
   public workSectionBudgets: WorkSectionBudgets[] = [];
}