
import { SchTravelRequestLegs } from './index';
import { NgTenants } from './index';

export class SchTravelerType {
   public travelerTypeId: number = 0;
   public travelerType: string = null;
   public travelerTypeCode: string = null;
   public clinAbbreviation: string = null;
   public tenantId: number = 0;
   public isActive: boolean = null;
   public schTravelRequestLegs: SchTravelRequestLegs[] = [];
   public tenant: NgTenants = null;
}