
import { HrWorkSections } from './index';
import { NgTenants } from './index';
import { TrQtHistory } from './index';
import { TrQtNotRequired } from './index';
import { TrQualTaskXPrerequisites } from './index';
import { TrQualTaskXTrainers } from './index';
import { TrQualXQualTasks } from './index';

export class TrQualTasks {
   public qualTaskId: number = 0;
   public qtDesc: string = null;
   public intervalInMonths: number = 0;
   public qualTaskUrlLink: string = null;
   public qualTaskWsOwnerId: number = 0;
   public hazwoper: boolean = false;
   public active: boolean = null;
   public tenantId: number = 0;
   public note: string = null;
   public qualTaskWsOwner: HrWorkSections = null;
   public tenant: NgTenants = null;
   public trQtHistory: TrQtHistory[] = [];
   public trQtNotRequired: TrQtNotRequired[] = [];
   public trQualTaskXPrerequisitesPreReqQualTask: TrQualTaskXPrerequisites[] = [];
   public trQualTaskXPrerequisitesQualTask: TrQualTaskXPrerequisites[] = [];
   public trQualTaskXTrainers: TrQualTaskXTrainers[] = [];
   public trQualXQualTasks: TrQualXQualTasks[] = [];
}