<div id="wrapper">
  <!-- Page Content -->
  <ol class="breadcrumb">
    <li class="active">AIMS - ASRC Federal Integrated Management System</li>
  </ol>

  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-3"></div>
      <div class="col-xs-6">
        <div class="k-header" style="padding: 1em">
        <div style="width:100%;text-align:center;"><img style="max-width:75%;" src="{{env.assetsPath}}/{{(user|async)?.splashPageImage}}" />
        </div>
        </div>
      </div>
      <div class="col-xs-3"></div>
    </div>
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /#wrapper -->