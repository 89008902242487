import {
  ContentChildren,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { AimsCommonTableFilterDirective } from './aims-common-table-filter.directive'

@Directive({
  selector: 'ng-template[aimsCommonTableDynamicRow]',
})
export class AimsCommonTableDynamicRowDirective {
  @Input() id: string

  constructor(public host: TemplateRef<ElementRef>) {}
}
