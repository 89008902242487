
import { HrEmployees } from './index';
import { NgTenants } from './index';
import { TrQualTaskXTrainers } from './index';

export class TrTrainers {
   public trainerId: number = 0;
   public employeeId: number = 0;
   public active: boolean = null;
   public tenantId: number = 0;
   public employee: HrEmployees = null;
   public tenant: NgTenants = null;
   public trQualTaskXTrainers: TrQualTaskXTrainers[] = [];
}