
import { AllHughesNetMacs } from './index';
import { HrEmployees } from './index';
import { NgTenants } from './index';
import { AllHughesNetUserTypes } from './index';

export class AllHughesNetUsers {
   public hughesNetUserId: number = 0;
   public lastName: string = null;
   public firstName: string = null;
   public middleInitial: string = null;
   public active: boolean = null;
   public userTypeId: number = null;
   public maxEffectiveDate: Date = null;
   public userComments: string = null;
   public employeeId: number = null;
   public fullName: string = null;
   public tenantId: number = 0;
   public allHughesNetMacs: AllHughesNetMacs[] = [];
   public employee: HrEmployees = null;
   public tenant: NgTenants = null;
   public userType: AllHughesNetUserTypes = null;
}