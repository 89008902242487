
import { QcObservations } from './index';
import { NgTenants } from './index';

export class QcOriginTypes {
   public originTypeId: number = 0;
   public originType: string = null;
   public active: boolean = null;
   public showQafields: boolean = false;
   public defaultToCdi: boolean = false;
   public tenantId: number = 0;
   public qcObservations: QcObservations[] = [];
   public tenant: NgTenants = null;
}