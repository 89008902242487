import { Component, OnInit, ViewChild, Inject, PLATFORM_ID, isDevMode } from '@angular/core';
import { Observable, of, combineLatest, BehaviorSubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { TransferHttpService, CacheItem } from '../../../../src/app/services/transfer-http/transfer-http.service';
import { PermissionsService } from '../../../../src/app/services/permissions/permissions.service';
import {UserService} from '../../services/user/user.service';
import { AuthService } from '../../services/auth/auth.service';
import { switchMap, take } from 'rxjs/operators';
import { environment } from '../../../../src/environments/environment';
import { ComponentEnum } from '../../../../src/app/services/permissions/component.enum';

@Component({
  selector: 'aims-navmenu',
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.css']
})
export class NavmenuComponent implements OnInit {
  loginName: string = 'authenticating...';

  public isCollapsed: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(private transferHttp: TransferHttpService,
    public permissions: PermissionsService,
    public userService: UserService,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    public auth: AuthService) { }

  user: any;
  passwordExp: number;

  env = environment;

  isDev: boolean = this.transferHttp.getCache(CacheItem.isDev);
  get sharePointLink(): string {
    let retVal: string = null;
    if (this.transferHttp.getCache(CacheItem.user) && this.transferHttp.tenantConfig){
      retVal = this.transferHttp.tenantConfig.sharePointLink;
    }
    return retVal;
  }

  get inventoryLink(): string {
    let l = "/inventory";
    //Reroute to report if can't read primary component. This allows govt access
    if (!this.permissions.canRead(ComponentEnum.ViewInventory)) {
        l = "/reporting/INV_Status/trdp";
    }
    return l;
  }

  get logisticsLink(): string {
    let l = "/logistics";
    //Reroute to report if can't read primary component. This allows govt access
    if (!this.permissions.canRead(ComponentEnum.LogisticsDashboard)) {
        l = "/logistics/externalReview";
    }
    return l;
  }

  get showAdminMenu(): boolean {
    return this.auth.showAdminMenu;
  }

  //Hack for bootstrap dropdown button
  @ViewChild('btnDD') btnDD;
  showUserDD: boolean = false;
  public showDD(show?: boolean): void {
    if (show != undefined) {
      this.showUserDD = show;
    }
    else {
      this.showUserDD = !this.showUserDD;
    }
    if (this.showUserDD) {
      this.btnDD.nativeElement.focus();
    }

  }

  logout(){
    this.auth.logout().pipe(take(1)).subscribe();
    this.showDD();
    sessionStorage.clear();
    this.router.navigate(['login']);
  }

  ngOnInit() {
    this.user = this.permissions.user;
    this.auth.LoggedIn.subscribe(ret => {
      if (ret && this.user) {
        this.passwordExp = this.userPasswordExpirationDays();
      }
      else {
        this.passwordExp = null;
      }
    });
  }

  userPasswordExpirationDays() {
    if (this.user == null || this.user.value == null) {
      return null;
    }

    const passwordExpDate = this.user.value.passwordExpirationDate;
    if (passwordExpDate instanceof Date === false) {
      return null;
    }

    const passwordExpMs = passwordExpDate.getTime();

    if (isNaN(passwordExpMs)) {
      return null;
    }

    const now = new Date().getTime();
    const diffMs = passwordExpMs - now;
    // ms per day = 1000 * 60 * 60 * 24 = 86400000
    const diffDays = diffMs / 86400000;
    return diffDays;
  }

  passwordExpMessage() {
    if (this.passwordExp == null) {
      return '';
    }

    if (this.passwordExp <= 0) {
      return 'PASSWORD EXPIRED';
    }

    if (this.passwordExp < 24) {
      return `PASSWORD EXPIRES IN ${Math.floor(this.passwordExp * 24)} HOURS`;
    }

    return `PASSWORD EXPIRES IN ${Math.ceil(this.passwordExp)} DAYS`;
  }
}
