

export class InvFileAttachment {
   public fileAttachmentId: number = 0;
   public fileAttachmentGuid: string = null;
   public fileAttachmentName: string = null;
   public fileAttachmentType: string = null;
   public fileAttachedToType: number = 0;
   public fileAttachedToId: number = 0;
   public tenantId: number = 0;
}