import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsService } from '../services/permissions/permissions.service';
import { AuthService } from '../services/auth/auth.service';

const NO_GAURD: Array<string> = [
  "login"
]

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private auth: AuthService,
    private router: Router,
    private permissions: PermissionsService) { }

  canActivate(next,
    state: RouterStateSnapshot): boolean {
    return this.checkAuth(state.url);
  }

  canActivateChild(next, state: RouterStateSnapshot): boolean {
    return this.checkAuth(state.url);
  }

  checkAuth(url: string): boolean {
    //No JWT Token?  To Login with you, added login check to prevent loop
    if (!this.auth.isLoggedIn()) {
      this.auth.redirectRoute = url;
      this.router.navigate([`login`]);
      return false;
    }

    //If user opens new tab or window, JWT token may exist, but user and permissions haven't loaded
    //Need to grab them here, before potentially navigating to password reset
    if (this.permissions.user === undefined || this.permissions.permissions.value == undefined || this.permissions.user.value == null || this.permissions.permissions.value.size == 0){
      this.auth.doLoginUser();
    }

    //If user has logged in using temp password force navigate to password reset
    if (!url.includes('passwordReset') && this.auth.forcePasswordReset){
      this.router.navigate([`profile/passwordReset`]);
      return false;
    }

    //admin should only be available if logged into Administration
    if (url.includes('admin') && !this.auth.showAdminMenu) {
      this.router.navigate([`/`]);
      return false;
    }

    return true;
  }

}
