
import { SchResources } from './index';
import { AccCopas } from './index';
import { HrEmployees } from './index';
import { InvScheduleItemXAsset } from './index';
import { HrJobTitles } from './index';
import { SchFirearms } from './index';
import { SchTravelRequestLegs } from './index';
import { SchScheduleItemStatus } from './index';
import { SchScheduleItemType } from './index';
import { AllSites } from './index';
import { NgTenants } from './index';

export class SchScheduleItem {
   public scheduleItemId: number = 0;
   public scheduleItemStatusId: number = 0;
   public scheduleItemTypeId: number = 0;
   public siteId: number = 0;
   public employeeId: number = null;
   public resourceId: number = null;
   public reliefForId: number = null;
   public overhireJtId: number = null;
   public oanumber: string = null;
   public scheduleItemStart: Date = new Date(0);
   public scheduleItemEnd: Date = new Date(0);
   public travelArrangeReq: boolean = false;
   public siteArrivalNoticeRequired: boolean = false;
   public govBilled: boolean = false;
   public companyBilled: boolean = false;
   public restrictedAccess: boolean = false;
   public restrictedAreaPhotography: boolean = false;
   public meals: boolean = false;
   public teraccess: boolean = false;
   public classifiedAccess: boolean = false;
   public comsecaccess: boolean = false;
   public escortOnly: boolean = false;
   public billeting: boolean = false;
   public notes: string = null;
   public createdOn: Date = new Date(0);
   public createdById: number = 0;
   public modifiedOn: Date = null;
   public modifiedById: number = null;
   public confirmArrival: boolean = null;
   public confirmDeparture: boolean = null;
   public carrierId: number = null;
   public flightNumber: string = null;
   public arrival: Date = null;
   public departure: Date = null;
   public arrivalCarrierSchItemId: number = null;
   public departureCarrierSchItemId: number = null;
   public purpose: string = null;
   public firearmRequest: boolean = false;
   public radarDowntime: boolean = false;
   public transToTopCamp: boolean = false;
   public tenantId: number = 0;
   public copaId: number = null;
   public carrier: SchResources = null;
   public copa: AccCopas = null;
   public createdBy: HrEmployees = null;
   public employee: HrEmployees = null;
   public invScheduleItemXAsset: InvScheduleItemXAsset[] = [];
   public modifiedBy: HrEmployees = null;
   public overhireJt: HrJobTitles = null;
   public reliefFor: HrEmployees = null;
   public resource: SchResources = null;
   public schFirearms: SchFirearms[] = [];
   public schTravelRequestLegs: SchTravelRequestLegs[] = [];
   public scheduleItemStatus: SchScheduleItemStatus = null;
   public scheduleItemType: SchScheduleItemType = null;
   public site: AllSites = null;
   public tenant: NgTenants = null;
}