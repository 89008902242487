
import { LogShippingCommHdr } from './index';

export class LogShippers {
   public shipperId: number = 0;
   public shipper: string = null;
   public tenantId: number = 0;
   public active: boolean = null;
   public logShippingCommHdrFinalShipper: LogShippingCommHdr[] = [];
   public logShippingCommHdrShipper: LogShippingCommHdr[] = [];
}