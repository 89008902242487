import { Component, OnInit, TemplateRef, ViewChild, Input } from '@angular/core'

@Component({
  selector: 'aims-common-table-def',
  templateUrl: './aims-common-table-def.component.html',
  styleUrls: ['./aims-common-table-def.component.css'],
})
export class AimsCommonTableDefComponent implements OnInit {
  @Input() id: string

  @ViewChild(TemplateRef) template: TemplateRef<any>

  constructor() {}

  ngOnInit(): void {}
}
