import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AimsCommonTableComponent } from './aims-common-table.component'
import { MatTableModule } from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatButtonModule } from '@angular/material/button'
import { RouterModule } from '@angular/router'
import { AimsCommonTableDefComponent } from './aims-common-table-def/aims-common-table-def.component'
import { MatInputModule } from '@angular/material/input'
import { MatToolbarModule } from '@angular/material/toolbar'
import { AimsCommonExportToExcelModule } from '../aims-common-export-to-excel/aims-common-export-to-excel.module'
import { AimsCommonConfirmDialogModule } from '../aims-common-confirm-dialog/aims-common-confirm-dialog.module'
import { AimsCommonButtonModule } from '../aims-common-button/aims-common-button.module'
import { AimsCommonButtonMiniModule } from '../aims-common-button-mini/aims-common-button-mini.module'
import { MatIconModule } from '@angular/material/icon'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatFormFieldModule } from '@angular/material/form-field'

import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AimsCommonTableExpandedRowDirective } from './aims-common-table-expanded-row/aims-common-table-expanded-row.directive'
import { AimsCommonTableDynamicRowDirective } from './aims-common-table-dynamic-row/aims-common-table-dynamic-row.directive'
import { AimsCommonTableEditableComponent } from './aims-common-table-editable/aims-common-table-editable.component'
import { ViewModeDirective } from './aims-common-table-editable/view-mode.directive'
import { EditableOnEnterDirective } from './aims-common-table-editable/edit-on-enter.directive'
import { EditModeDirective } from './aims-common-table-editable/edit-mode.directive'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatSelectModule } from '@angular/material/select'
import { AimsCommonTableFilterDirective } from './aims-common-table-dynamic-row/aims-common-table-filter.directive'
import { AimsCommonTableDynamicRowComponent } from './aims-common-table-dynamic-row/aims-common-table-dynamic-row.component'
import { AimsCommonTableDynamicToolbarDirective } from './aims-common-table-dynamic-toolbar/aims-common-table-dynamic-toolbar'
import { MatMenuModule } from '@angular/material/menu'
import { DateFilterComponent } from './date-filter/date-filter.component'
import { AimsCommonTableFooterDirective } from './aims-common-table-dynamic-row/aims-common-table-footer.directive'
import { AimsCommonTableHeaderDirective } from './aims-common-table-dynamic-row/aims-common-table-header.directive'

@NgModule({
  declarations: [
    AimsCommonTableComponent,
    AimsCommonTableDefComponent,
    AimsCommonTableExpandedRowDirective,
    AimsCommonTableDynamicRowDirective,
    AimsCommonTableEditableComponent,
    ViewModeDirective,
    EditableOnEnterDirective,
    EditModeDirective,
    AimsCommonTableFilterDirective,
    AimsCommonTableFooterDirective,
    AimsCommonTableHeaderDirective,
    AimsCommonTableDynamicRowComponent,
    AimsCommonTableDynamicToolbarDirective,
    DateFilterComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    MatToolbarModule,
    AimsCommonExportToExcelModule,
    AimsCommonConfirmDialogModule,
    MatDatepickerModule,
    MatMenuModule,
    MatProgressBarModule,
    AimsCommonButtonModule,
    AimsCommonButtonMiniModule,
  ],
  exports: [
    AimsCommonTableComponent,
    AimsCommonTableDefComponent,
    AimsCommonTableEditableComponent,
    AimsCommonTableExpandedRowDirective,
    AimsCommonTableDynamicRowDirective,
    ViewModeDirective,
    EditModeDirective,
    AimsCommonTableFilterDirective,
    AimsCommonTableFooterDirective,
    AimsCommonTableHeaderDirective,
    AimsCommonTableDynamicRowComponent,
    AimsCommonTableDynamicToolbarDirective,
  ],
})
export class AimsCommonTableModule {}
