import {
  Component,
  ContentChild,
  ContentChildren,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
} from '@angular/core'
import { EditModeDirective } from '../aims-common-table-editable/edit-mode.directive'
import { ViewModeDirective } from '../aims-common-table-editable/view-mode.directive'
import { AimsCommonTableFilterDirective } from './aims-common-table-filter.directive'
import { AimsCommonTableFooterDirective } from './aims-common-table-footer.directive'
import { AimsCommonTableHeaderDirective } from './aims-common-table-header.directive'

@Component({
  selector: 'aims-common-table-dynamic-row',
  templateUrl: './aims-common-table-dynamic-row.component.html',
  styleUrls: ['./aims-common-table-dynamic-row.component.css'],
})
export class AimsCommonTableDynamicRowComponent implements OnChanges {
  @Input() id
  @Input() inputMode
  @Input() element

  @ContentChild(ViewModeDirective) viewModeTpl: ViewModeDirective
  @ContentChild(EditModeDirective) editModeTpl: EditModeDirective
  @ContentChild(AimsCommonTableFilterDirective) filtersTpl: AimsCommonTableFilterDirective
  @ContentChild(AimsCommonTableFooterDirective) footerTpl: AimsCommonTableFooterDirective
  @ContentChild(AimsCommonTableHeaderDirective) headerTpl: AimsCommonTableHeaderDirective

  mode: 'view' | 'edit' = 'view'

  constructor() {}

  ngAfterContentInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.mode = this.inputMode ? this.inputMode : this.mode
  }

  get currentView() {
    return this.mode === 'view' ? this.viewModeTpl.tpl : this.editModeTpl.tpl
  }
}
