
import { HrEmployees } from './index';
import { NgTenants } from './index';
import { TrQualHistory } from './index';
import { TrQualNotRequired } from './index';
import { TrQualXAssigned } from './index';
import { TrQualXJobAssignments } from './index';
import { TrQualXJobTitles } from './index';
import { TrQualXQualTasks } from './index';

export class TrQuals {
   public qualId: number = 0;
   public qualDesc: string = null;
   public requiredByAllEmps: boolean = false;
   public active: boolean = null;
   public qualOwnerId: number = 0;
   public tenantId: number = 0;
   public qualOwner: HrEmployees = null;
   public tenant: NgTenants = null;
   public trQualHistory: TrQualHistory[] = [];
   public trQualNotRequired: TrQualNotRequired[] = [];
   public trQualXAssigned: TrQualXAssigned[] = [];
   public trQualXJobAssignments: TrQualXJobAssignments[] = [];
   public trQualXJobTitles: TrQualXJobTitles[] = [];
   public trQualXQualTasks: TrQualXQualTasks[] = [];
}