
import { LogReqHeader } from './index';
import { StdText } from './index';

export class LogReqTextCodes {
   public reqTextCodeId: number = 0;
   public reqId: string = null;
   public textCd: string = null;
   public textCdRvsnNo: number = 0;
   public req: LogReqHeader = null;
   public textCdNavigation: StdText = null;
}