
import { HrEmployees } from './index';
import { WoOrigins } from './index';
import { WoScheduledMaintenanceStatus } from './index';
import { WoScheduledMaintenanceTemplateTypes } from './index';
import { NgTenants } from './index';
import { WoClassifications } from './index';
import { WoStatuses } from './index';
import { WoScheduledMaintenanceComment } from './index';
import { WoScheduledMaintenancePartsEstimate } from './index';
import { WoScheduledMaintenanceTemplateEquipmentXs } from './index';
import { HrEmployeeJobAssignments } from './index';
import { WoTaskingPriority } from './index';
import { WoWorkOrders } from './index';
import { WoWorkOrderTypes } from './index';

export class WoScheduledMaintenanceTemplates {
   public scheduledMaintenanceTemplateId: number = 0;
   public scheduledMaintenanceStatusId: number = 0;
   public scheduledMaintenanceTemplateTypeId: number = 0;
   public shadowedByScheduledMaintenanceTemplateId: number = null;
   public generateXdaysBeforeDue: number = 0;
   public generateXhoursBeforeDue: number = 0;
   public monthsBetween: number = 0;
   public daysBetween: number = 0;
   public meterHoursBetween: number = 0;
   public woRequiresLeadApproval: boolean = false;
   public woEstimatedLaborHours: number = 0;
   public woDescription: string = null;
   public woClassificationId: number = 0;
   public woFilePlanRequired: boolean = false;
   public woSpecialtyId: number = null;
   public woInstructions: string = null;
   public woRequiresDowntime: boolean = false;
   public woDefaultStatusId: number = 0;
   public woEstMatCost: number = null;
   public createdById: number = 0;
   public lastUpdatedById: number = 0;
   public createdOn: Date = new Date(0);
   public lastUpdatedDateTime: Date = new Date(0);
   public oldId: number = null;
   public oldShadowedById: number = null;
   public woRequiredDataJson: string = null;
   public tenantId: number = 0;
   public woTaskPriorityId: number = null;
   public workOrderTypeId: number = null;
   public referenceNumber: string = null;
   public preventCreateOnWeekend: boolean = false;
   public originId: number = null;
   public createdBy: HrEmployees = null;
   public inverseShadowedByScheduledMaintenanceTemplate: WoScheduledMaintenanceTemplates[] = [];
   public lastUpdatedBy: HrEmployees = null;
   public origin: WoOrigins = null;
   public scheduledMaintenanceStatus: WoScheduledMaintenanceStatus = null;
   public scheduledMaintenanceTemplateType: WoScheduledMaintenanceTemplateTypes = null;
   public shadowedByScheduledMaintenanceTemplate: WoScheduledMaintenanceTemplates = null;
   public tenant: NgTenants = null;
   public woClassification: WoClassifications = null;
   public woDefaultStatus: WoStatuses = null;
   public woScheduledMaintenanceComment: WoScheduledMaintenanceComment[] = [];
   public woScheduledMaintenancePartsEstimate: WoScheduledMaintenancePartsEstimate[] = [];
   public woScheduledMaintenanceTemplateEquipmentXs: WoScheduledMaintenanceTemplateEquipmentXs[] = [];
   public woSpecialty: HrEmployeeJobAssignments = null;
   public woTaskPriority: WoTaskingPriority = null;
   public woWorkOrders: WoWorkOrders[] = [];
   public workOrderType: WoWorkOrderTypes = null;
}