import { Injectable } from '@angular/core';
import * as moment from 'moment-mini';

//Array containing properties to not ever check for date
const notDate: Array<string> = [
  "partNumber",
  "vendPartNumber",
  "vendPartNum",
  "serialNo",
  "supplyDocNumber",
  "proposalPrice",
  "venD_PART_ID",
  "meterReading",
  "reqId",
  "reqID",
  "drawingNum",
  "driversLicense",
  "driversLicenseFrom",
  "driversLicenseTo",
  "trackingNo",
  "trackingNumber",
  "partId",
  "model",
  "vendId",
  "manufPartId",
  "vendPartId",
  "serialNumber",
  "vendorId",
  "tttCesworkOrderNum",
  "poId",
  "prevMeterReading",
  "lastMeterReading",
  "workOrderNumber",
  "estimatedUnitPrice",
  "lastGenHours",
  "attachedToName"
];

//Array of fields that are date only in the db
//Get translated to midnight local time
const dateOnlyField: Array<string> = [
    "readingDate",
    "weekEndingDate",
    "readingDate",
    "beginDate",
    "endDate",
    "weekOf",
    "modifiedDate",
    "dateAdded",
    "testDate",
    "effectiveOn",
    "probationStartFrom",
    "probationStartTo",
    "probationaryCompletionDateFrom",
    "probationaryCompletionDateTo",
    "driversLicenseExpDateFrom",
    "driversLicenseExpDateTo",
    "bridgedDateOfHireFrom",
    "bridgedDateOfHireTo",
    "dispatchDateFrom",
    "dispatchDateTo",
    "originalDateOfHireFrom",
    "originalDateOfHireTo",
    "clearanceDateFrom",
    "clearanceDateTo",
    "cacExpDateFrom",
    "cacExpDateTo",
    "recallExpireDateFrom",
    "recallExpireDateTo",
    "maxValidDate",
    "assignedDate"
];

@Injectable({
  providedIn: 'root'
})
export class HandleJsonService {

  constructor() { }

  private isIsoDate(str: string) : boolean {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) {
      return false;
    }
    const d = new Date(str);
    return d instanceof Date && !isNaN(d.getTime()) && d.toISOString()===str; // valid date
  }


  //Converts JSON date to objects
  private handleObject(obj: Object) {
    try {
      for (let prop in obj) {
        //Anything in the notDate array is not checked
        //Adding check for the iso format since the moment strict check doesn't seem to work
        if (notDate.indexOf(prop) == -1) {
          let mom = moment(obj[prop], moment.ISO_8601, true);
          if (mom.isValid()) {
            if (moment('1983').isAfter(mom)) {
              mom.add(2, 'hours');//AK time was -10 prior to 1983, Entity Framework JSON date parser doesn't account for this, but Javascript does
            }
            //If midnight UTC assume its a date only field and we need to convert it to midnight local to remove timezone offset
            //if we have specified its a date field in array above
            const midnight = moment.utc(mom).startOf('date');
            const offset = mom.utcOffset();
            if (mom.isSame(midnight) && dateOnlyField.indexOf(prop) != -1){
                mom = moment(mom.add(-offset,'minutes').startOf('date'));
            }
            let date: Date = mom.toDate();
            obj[prop] = date;

          }
          //Handle child object
          else if (typeof obj[prop] == 'object'){
              this.handleObject(obj[prop]);
          }
          //Handle levels of arrays of objects
          else if (Array.isArray(obj[prop])) {
            for (let lvlObj of obj[prop]) {
              this.handleObject(lvlObj);
            }
          }
        }
      }
    }
    catch (e) {
      console.log(e);
    }

  }

  public handleMapping(res) {
    let jsonData = res;
    if (Array.isArray(jsonData)) {
      for (let obj of jsonData) {
        this.handleObject(obj);
      }
    }
    else {
      //Do this if returning single object instead of array
      this.handleObject(jsonData);
    }
    return jsonData;
  }
}
