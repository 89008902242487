
import { LogReqHeader } from './index';

export class LogClinCodes {
   public clinCodeId: number = 0;
   public clinCode: string = null;
   public contractYear: string = null;
   public clinDescription: string = null;
   public paidBy: string = null;
   public ars: boolean = null;
   public abbreviation: string = null;
   public tenantId: number = 0;
   public active: boolean = null;
   public logReqHeader: LogReqHeader[] = [];
}