import { BrowserModule, BrowserTransferStateModule, TransferState, makeStateKey, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, isDevMode } from '@angular/core';
import { FormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavmenuComponent } from './components/navmenu/navmenu.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeComponent } from './containers/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuillModule } from 'ngx-quill';
import { environment } from '../../src/environments/environment';
import { CacheDataInterceptor } from './interceptors/cacheData.interceptor';
import { UpdateUrlInterceptor } from './interceptors/updateUrl.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { SharedModule } from './shared/shared.module';

//Function provides pre-loaded data from the server that is cached in memory
export function serverInit(http: HttpClient,
  transferState: TransferState
) {

  return () => {
    //TransferData will load server side and get passed via transfer state in prod, will load browser side in dev
    return http.get(`${environment.apiUrl}/Common/GetTransferData`)
      .toPromise()
      .catch(e => console.log(" $$$$$$$$$$$$$$$$ TRANSFER DATA PROMISE ERROR: \n" + e))
      .then(transferData => {
        if (transferData) {
          Object.keys(transferData)
            .forEach(key => {
              if (transferData[key] !== null && transferData[key] !== undefined) {
                transferState.set(makeStateKey(key), (transferData[key].value !== undefined) ? transferData[key].value : transferData[key]);
              }
            });
        }
      });
  }
}

const appProviders =
//(!environment.production) ? 
[
  Title,
  { //This only runs in dev mode because if its running in prod, its called server side and cached
    provide: APP_INITIALIZER,
    useFactory: serverInit,
    multi: true,
    deps: [HttpClient, TransferState]
  },
  { provide: HTTP_INTERCEPTORS, useClass: CacheDataInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: UpdateUrlInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
]
// :
// [
//   Title,
//   { provide: HTTP_INTERCEPTORS, useClass: CacheDataInterceptor, multi: true },
//   { provide: HTTP_INTERCEPTORS, useClass: UpdateUrlInterceptor, multi: true },
//   { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
// ]
;



@NgModule({
  declarations: [
    AppComponent,
    NavmenuComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    QuillModule.forRoot(),
    FormsModule,
    SharedModule,
  ],
  providers: appProviders,
  bootstrap: [AppComponent]
})
export class AppModule { }


